* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
:root {
  --color-131921: #131921;
  --color-3b4149: #3b4149;
  --color-febd69: #febd69;
  --color-F6D72F: #F6D72F;
  --color-232f3e: #232f3e;
  --color-2e353b: #2e353b;
  --color-blue: #2874f0;
  --color-bf4800: #bf4800;
  --color-f5f5f7: #f5f5f7;
  --color-ededed: #ededed;
  --color-777777: #777777;
  --color-1c1c1b: #1c1c1b;
  --color-blue-shick: rgba(43,110,248,1);
  --color-white-shick: #fffff;
  --color-band-head: rgba(245,245,245,1);
}
.error{color:red; font-size: 12px;}
.section-heading {
  font-size: 26px;
  line-height: 32px;
  font-weight: 500;
  margin-bottom: 30px;
}
.card-wrapper {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}
body {
  font-family: "Rubik", sans-serif!important;
}
a {
  text-decoration: none!important;
  display: inline-block;
}
.form-control:focus {
  box-shadow: none;
  border-color: inherit;
}

.gap-10 {
  gap: 10px;
}
.gap-15 {
  gap: 15px;
}
.gap-30 {
  gap: 30px;
}

.button {
  /* background-color: var(--color-232f3e); */
  background-color: var(--color-blue);
  color: white;
  font-size: 14px;
  padding: 13px 33px;
  border-radius: 25px;
}
.button:hover {
  /* background-color: var(--color-febd69); */
  background-color: var(--color-F6D72F);
  color: #131921;
}
/* Header Part */

.header-top-strip {
  /* border-bottom: 1px solid var(--color-3b4149); */
  /* background-color: var(--color-131921); */
  background-color: var(--color-band-head);
}
.header-upper {
  /* background-color: var(--color-131921); */
  background-color: var(--color-white-shick);
}
.header-bottom {
  /* background-color: var(--color-232f3e); */
  background-color: var(--color-blue-shick);
}
.text-black{color: #000;}
.header-upper .input-group-text {
  background-color: var(--color-F6D72F);
  border: var(--bs-border-width) solid var(--color-F6D72F);
}
.header-upper.header-upper-links img {
  width: 35px;
  height: 35px;
}
.header-bottom .menu-links a,
.header-bottom .dropdown button {
  color: white;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: 0.3;
  text-transform: uppercase;
}

.header-bottom .dropdown button:focus {
  box-shadow: none;
}
.header-bottom .dropdown-menu {
  background-color: var(--color-131921);
  width: 100%;
  transform: translate3d(0px, 57px, 0px) !important;
}
.header-bottom .dropdown-item {
  padding: 20px;
  margin-bottom: 3px;
  border-bottom: 1px solid var(--color-3b4149);
}
.header-bottom .dropdown-item:hover {
  background-color: transparent;
  /* color: var(--color-febd69) !important; */
  color: var(--color-F6D72F) !important;
}
/* Header Part End Here */

/* Footer Part */

footer {
  background-color: var(--color-2e353b);
}
.react-slideshow-container .default-nav{
  display:none!important;
}
footer:not(:first-child) {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
footer .input-group-text {
  background-color: var(--color-232f3e);
  /* color: white; */
  color: #000;
  padding: 8px 17px;
}
/* Footer Part Ends Here */

/* Home Page */
.main-banner-content {
  top: 15%;
  left: 5%;
}
.main-banner-content h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}
.main-banner-content h5 {
  font-size: 42px;
  line-height: 54px;
  letter-spacing: -2px;
  font-weight: 500;
  text-transform: none;
}

.main-banner-content p {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.4;
  color: var(--color-131921);
}
.small-banner-content {
  top: 25%;
  left: 10%;
}
.small-banner-content h4 {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: var(--color-bf4800);
  margin: 0 0 12px;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}
.small-banner-content h5 {
  font-size: 22px;
  line-height: 34px;
  letter-spacing: -2px;
  font-weight: 500;
  text-transform: none;
}

.small-banner-content p {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.4;
  color: var(--color-131921);
}
.small-banner {
  width: 49%;
}

/* home wrapper 2 */
.home-wrapper-2 {
  background-color: var(--color-f5f5f7);
}

/* home wrapper 2 ends */

/* categories section */

.categories {
  background-color: white;
  box-shadow: 0 0 10px #0000001a;
  padding: 15px;
}
.categories > div {
  width: 25%;
  padding: 10px 10px 10px 20px;
}
.categories > div > div {
  width: 60%;
}
.categories > div:nth-child(1),
.categories > div:nth-child(2),
.categories > div:nth-child(3),
.categories > div:nth-child(4) {
  border-bottom: 1px solid var(--color-ededed);
}
.categories > div:nth-child(1),
.categories > div:nth-child(2),
.categories > div:nth-child(3) {
  border-right: 1px solid var(--color-ededed);
}
.categories > div:nth-child(5),
.categories > div:nth-child(6),
.categories > div:nth-child(7) {
  border-right: 1px solid var(--color-ededed);
}
/* categories section ends here */
/* Home Page Ends */

/* BLog Part */

.blog-card {
  background-color: white;

  border-radius: 10px;
}
.blog-card img {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.blog-content {
  padding: 15px;
}
.blog-contentp.date {
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0;
  font-weight: 400;
  color: var(--color-777777);
  padding: 0;
}
.blog-content h5 {
  font-size: 18px;
  color: var(--color-131921);
}
.blog-content p.desc {
  font-size: 13px;
  line-height: 22px;
  color: var(--color-777777);
}
.single-blog-card a {
  font-size: 18px;
  color: var(--color-777777);
  margin-bottom: 20px;
}
.single-blog-card h3 {
  font-size: 24px;
  font-weight: 600;
  color: var(--color-1c1c1b);
}
.single-blog-card p {
  color: var(--color-777777);
  font-size: 14px;
}
/*  Blog Part ends here */

/* Product Card */

.product-card {
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}
.product-card .product-image {
  height: 270px;
}
.product-card .product-image img {
  object-fit: contain;
}
.product-card .product-image > img:last-child {
  display: none;
}
.product-card:hover .product-image > img:nth-child(1) {
  display: none;
}
.product-card:hover .product-image > img:last-child {
  display: block;
}
.product-card:hover .action-bar {
  right: 15px;
}
.product-card .product-details h6 {
  color: var(--color-bf4800);
  font-size: 13px;
}
.product-card .product-details h5 {
  font-size: 15px;
  color: var(--color-1c1c1b);
}
.product-card .product-details p.price {
  font-size: 16px;
  color: var(--color-1c1c1b);
}
.product-card p.description {
  font-size: 13px;
  color: var(--color-777777);
  margin-right: 20px;
}
.action-bar {
  top: 10%;
  right: -23px;
  transition: 0.3s;
}
.wishlist-icon {
  top: 2%;
  right: 15px;
}
/* Product Card ends here */

/* Special Product */

.special-product-card {
  padding: 20px 10px;
  border-radius: 10px;
  background-color: white;
}

/* Special Product ends here */

/* famous card */

.famous-card .famous-content {
  top: 10%;
  left: 5%;
}
.famous-card img {
  border-radius: 10px;
}
.famous-content h5 {
  font-size: 13px;
  line-height: 20px;
  font-weight: 400px;
  color: white;
  margin-bottom: 7px;
  text-transform: uppercase;
}
.famous-content h6 {
  font-size: 24px;
  line-height: 38px;
  font-weight: 500;
  color: white;
}
.famous-content p {
  font-size: 16px;
  line-height: 24px;
  font-weight: 100;
  color: white;
}
/* famous card ends */

/* Our Store */
.filter-card {
  background-color: white;
  border-radius: 10px;
  padding: 15px 15px 25px;
}
.filter-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 20px;
}
.filter-card ul,
.filter-card .form-check-label {
  list-style-type: none;
  font-size: 13px;
  line-height: 30px;
  color: var(--color-777777);
  cursor: pointer;
}
.filter-card .form-check-input:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0.25);
}
.filter-card .form-check-input:checked {
  background-color: var(--color-F6D72F);
  /* background-color: var(--color-febd69); */
  border-color: var(--color-F6D72F);
  /* border-color: var(--color-febd69); */
}
.sub-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 15px;
  margin-top: 20px;
}
.colors {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.colors li {
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
}
.random-products h5 {
  font-size: 14px;
  margin-bottom: 8px;
}
.random-products:first-child {
  border-bottom: 1px solid var(--color-ededed);
}
.filter-sort-grid {
  padding: 10px;
  background-color: white;
  border-radius: 10px;
}
.grid img {
  width: 35px;
  height: 35px;
  background-color: #f2f2f2;
  padding: 8px;
  border-radius: 3px;
  cursor: pointer;
}
.gr-12 {
  width: 100%;
}
.gr-6 {
  width: 49%;
}
.gr-4 {
  width: 32%;
}
.gr-3 {
  width: 24%;
}
.gr-12 .product-card {
  display: flex;
  gap: 20px;
}
.gr-6 .product-image {
  width: 100%;
}
.gr-6 .product-image img {
  margin: auto;
  display: block;
}
.gr-12 .product-card .product-image {
  width: 270px;
}
.gr-12 .product-card .product-image {
  height: auto;
}
.gr-12 .action-bar {
  top: 17%;
}
/* Our store ends here */

/* Contact Page */
.contact-inner-wrapper {
  padding: 30px 20px;
  border-radius: 15px;
  background-color: white;
  gap: 15px;
}
.contact-inner-wrapper > div {
  width: 48%;
}
.contact-title {
  font-size: 26px;
  font-weight: 500;
  line-height: 35px;
  text-align: left;
}
.contact-inner-wrapper form .form-control {
  background-color: var(--color-f5f5f7);
  border-radius: 10px;
  border-color: transparent;
  padding: 10px;
}
.contact-inner-wrapper ul {
  list-style: none;
}
.contact-inner-wrapper address,
.contact-inner-wrapper a,
.contact-inner-wrapper p {
  color: var(--color-777777);
}
/* Contact Page ends */

/* Compare Product Page */

.compare-product-card {
  padding: 20px 15px;
  background-color: white;
  border-radius: 10px;
}
.compare-product-card .cross,
.wishlist-card .cross {
  top: 15px;
  right: 15px;
  width: 25px;
  padding: 3px;
  cursor: pointer;
}
.compare-product-details h5.title,
.wishlist-card h5.title {
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  color: var(--color-1c1c1b);
}
.compare-product-details h6.price,
.wishlist-card h6.price {
  font-size: 15px;
  line-height: 22px;
  font-weight: 400;
}
.compare-product-card .product-detail {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
/* Compare Product Page end here */

/*  auth card */

.auth-card {
  padding: 20px;
  background-color: white;
  width: 500px;
  margin: 30px auto;
  border-radius: 10px;
}
.auth-card h3 {
  font-size: 19px;
  font-weight: 500;
  color: var(--color-777777);
}
.auth-card p {
  font-size: 13px;
  color: var(--color-777777);
}
.auth-card .form-control {
  height: 50px;
  background-color: var(--color-f5f5f7);
  color: var(--color-777777);
  font-size: 14px;
  border-radius: 10px;
  border-color: transparent;
}
.auth-card a {
  font-size: 14px;
  font-weight: 400;
  color: var(--color-1c1c1b);
}
.signup {
  /* background-color: var(--color-febd69); */
  background-color: var(--color-F6D72F);
  color: var(--color-131921);
  border: none;
}
.signup:hover {
  background-color: var(--color-131921);
  color: white;
}
/*  auth card  ends*/

/* Policy Pages */

.policy {
  background-color: white;
  padding: 30px;
}
/* Policy Pages  ends */

/* Main Product Page */

.description-wrapper h4,
.reviews-wrapper h3 {
  font-size: 26px;
  color: #1c1c1b;
  margin-bottom: 10px;
}
.description-wrapper p {
  font-size: 14px;
  color: var(--color-777777);
}

.review-inner-wrapper {
  background-color: white;
  padding: 30px;
}
.review-head {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-bottom: 20px;
}
.review-head h4 {
  font-size: 18px;
  color: var(--color-1c1c1b);
}
.review-head p,
.review-head a {
  font-size: 14px;
  color: var(--color-777777);
}
.review-form h4 {
  font-size: 16px;
  color: var(--color-777777);
}
.review-form {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.review:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.review p {
  font-size: 14px;
  color: var(--color-777777);
}

.main-product-image {
  padding: 20px;
  background-color: white;
  border-radius: 10px;
}
.main-product-image > div {
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.main-product-image img {
  width: 100% !important;
  height: 600px !important;
  object-fit: cover;
}
.other-product-images {
  background-color: white;
  padding: 20px;
}
.other-product-images > div {
  padding: 20px;
  width: 48%;
  border: 1px solid rgba(0, 0, 0, 0.25);
}
.main-product-image .js-image-zoom__zoomed-image {
  left: 50px !important;
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  z-index: 1000;
}
.main-product-details {
  padding: 30px 20px;
  background-color: white;
  border-radius: 10px;
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}
.main-product-details h3.title {
  font-size: 18px;
  font-weight: 600;
  color: var(--color-1c1c1b);
  margin-bottom: 10px;
}
.main-product-details p.price {
  font-size: 16px;
  line-height: 20px;
  font-weight: 600;
  margin-bottom: 10px;
}
.main-product-details a.review-btn,
.t-review {
  color: var(--color-777777);
  font-size: 13px;
}
.product-heading {
  font-size: 14px;
  color: var(--color-1c1c1b);
  margin-bottom: 0px;
}
.product-data {
  font-size: 13px;
  columns: var(--color-777777);
  margin-bottom: 0px;
}
.main-product-details a {
  font-size: 14px;
  color: var(--color-777777);
}
/* Main Product Page */

/* Cart Page */
.cart-header,
.cart-data {
  border-bottom: 1px solid #eaeaea;
}
.cart-header h4 {
  font-size: 13px;
  color: var(--color-777777);
}
.cart-col-1 {
  width: 40%;
}
.cart-col-1 p {
  color: var(--color-777777);
  font-size: 14px;
}
.cart-col-2 {
  width: 10%;
}
.cart-col-3 {
  width: 15%;
}
.cart-col-4 {
  width: 15%;
}
.cart-data h5.price {
  font-size: 14px;
  color: var(--color-1c1c1b);
}
/* Cart Page Ends here */

/* Shippping Page */

.total {
  font-size: 14px;
  color: var(--color-1c1c1b);
}
.total-price {
  font-size: 16px;
  color: var(--color-777777);
}
/* Shipping page ends here */
.success-animation { margin:50px auto;}

.checkmark {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #4bb71b;
    stroke-miterlimit: 10;
    box-shadow: inset 0px 0px 0px #4bb71b;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both;
    position:relative;
    top: 5px;
    right: 5px;
   margin: 0 auto;
}
.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4bb71b;
    fill: #fff;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
 
}

.checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes scale {
    0%, 100% {
        transform: none;
    }

    50% {
        transform: scale3d(1.1, 1.1, 1);
    }
}

@keyframes fill {
    100% {
        box-shadow: inset 0px 0px 0px 30px #4bb71b;
    }
}
.dropdown:hover >,
.dropend:hover > {
  display: block;
  margin-top: -0.125em;
}

@media screen and (min-width) {
  .dropend:hover > {
    position: absolute;
    top: 0;
    left: 100%;
    margin-top: -0.125em;
  }
}
.dropdown-menu{border-radius: 0!important}
.dropend .dropdown-menu{top: -5px;margin-left: 0;}
.header-bottom .dropdown-item, .dropdown-menu .dropdown-toggle{padding: 10px 20px!important; font-size: 14px;
  margin-bottom: 3px;border-bottom:none;color: var(--color-777777)!important;}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link{color: #fff;font-size: 14px;}
.header-bottom .dropdown-menu{background-color: #fff; border:1px solid #eeeeee}
.text-white.dropdown > .dropdown-menu.show{top: 4px; width: auto;}
.text-gris{
  color: var(--color-777777);
}
nav.bg-body-tertiary {background: transparent!important;}


.header-bottom .dropdown-item:hover{background: #f8f8f8;}
.badge.rounded-pill.bg-danger{margin-top: -20px;
  margin-left: -20px;}
.badge-product .badge.rounded-pill.bg-danger{
  margin-top: auto;
  position: absolute;
  margin-left: auto;
  right: 0;
  top: -10px;}
.footer-clean{background: var(--color-band-head);}
.footer-blue{background: var(--color-blue-shick);}
footer .input-group-text{background: var(--color-F6D72F);border: var(--bs-border-width) solid var(--color-F6D72F);}
footer a:hover{color:var(--color-F6D72F)}
button[type="submit"]{border: none;}
.categories a{color: #000;}
.product-image{text-align: center;}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>");
}
.navbar-light .navbar-toggler{border-color:#fff;}
.ads-block > div:nth-child(1), .ads-block > div:nth-child(2){margin-bottom: 20px;}

/*Responsive*/
@media (max-width: 992px) {
  footer {text-align: center;}
  footer .social_icons, footer .footer-top-data{justify-content: center;}
  .footer-top-data{margin-bottom: 15px;}
  .navbar-brand
{
    display: none;
  }
  .titre-logo{text-align: center;}
  .header-upper-links{margin-top: 15px;}
}
@media (max-width: 768px) {
  .filter-sort-grid{flex-direction: column;}
  .small-ban{margin-top: 15px!important;}
  .categories > div{width: 50%;}
  .categories > div:nth-child(2),.categories > div:nth-child(6){border-right: 0px solid var(--color-ededed);}
  .categories > div:nth-child(5),.categories > div:nth-child(6){border-bottom: 1px solid var(--color-ededed);}
  .slider-block{margin-top: 15px;}
  .ads-block > div{margin-bottom: 20px;}
  .product-card{margin-bottom: 15px;}

}
@media (max-width: 575px) {
.ads-block img {width: 100%;}
.header-top-strip, .header-top-strip .text-end{text-align: center!important;}
.filter-sort-grid > div {flex-direction: column;     row-gap: 10px;}
.auth-card{width: auto;}
}
@media (max-width: 540px) {
  .categories > .d-flex{flex-direction: column;}
  .categories > div:nth-child(5), .categories > div:nth-child(3), .categories > div:nth-child(1)
{
  border-right: none;
}
.categories > div {padding: 10px 10px 10px 10px; text-align: center;}
  .gr-3{width: 49%;}
  .categories > div{width: 100%;}
  .main-product-image img{height: auto!important;}
}
@media (max-width: 523px) {
  .gr-3{width: 100%;}
  .gr-4{width: 49%;}
  .main-product-details .d-flex.align-items-center.gap-15.flex-row.mt-2.mb-3{flex-direction: column !important;}
}
@media (max-width: 523px) {
  .product-details{text-align: center;}
  .gr-4{width: 100%;}
  .gr-6{width: 100%;} 
}
@media (max-width: 438px) {
  .other-product-images > div{width: 45%;}
}
